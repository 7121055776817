/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { clamp as t } from "./mathUtils.js";
class n {
  constructor(t, n) {
    this.min = t, this.max = n, this.range = n - t;
  }
  normalize(t, n = 0, o = !1) {
    return i(this.range, this.min, this.max, t, n, o);
  }
  clamp(n, i = 0) {
    return t(n - i, this.min, this.max) + i;
  }
  monotonic(t, n, i) {
    return t < n ? n : n + o(this.range, t - n, i);
  }
  minimalMonotonic(t, n, o) {
    return i(this.range, t, t + this.range, n, o);
  }
  center(t, n, i) {
    return n = this.monotonic(t, n, i), this.normalize((t + n) / 2, i);
  }
  diff(t, n, i) {
    return this.monotonic(t, n, i) - t;
  }
  shortestSignedDiff(t, n) {
    t = this.normalize(t);
    const i = (n = this.normalize(n)) - t,
      o = n < t ? this.minimalMonotonic(t, n) - t : n - this.minimalMonotonic(n, t);
    return Math.abs(i) < Math.abs(o) ? i : o;
  }
  contains(t, n, i) {
    return n = this.minimalMonotonic(t, n), (i = this.minimalMonotonic(t, i)) > t && i < n;
  }
}
function i(t, n, i, r, s = 0, a = !1) {
  return (r -= s) < n ? r += o(t, n - r) : r > i && (r -= o(t, r - i)), a && r === i && (r = n), r + s;
}
function o(t, n, i = 0) {
  return Math.ceil((n - i) / t) * t + i;
}
const r = new n(0, 2 * Math.PI),
  s = new n(-Math.PI, Math.PI),
  a = new n(0, 360);
export { n as Cyclical, r as cyclical2PI, a as cyclicalDegrees, s as cyclicalPI };